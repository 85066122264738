<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="accordion" id="accordionExample">
        <div class="accordion__element">
          <div class="accordion__header">
            <h2 class="">
              <span class="accordion__header__title"> Currency In Use </span>
              <button
                class="btn accordion__header__button float-right"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
              >
                <span></span> <ion-icon name="chevron-down-sharp"></ion-icon>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="accordion__body">
              <button
                class="button page__body__header__button ml-auto button--grey text--capital mb-2"
                type="button"
                data-toggle="modal"
                data-target="#newCurrencyModal"
                v-if="userCanCreateCurrency"
              >
                Add Currency <i class="ki ki-plus icon-sm ml-3"> </i>
              </button>
              <table class="table customtable">
                <thead class="table__header">
                  <tr class="table__header__text table-text">
                    <th scope="col">Code</th>
                    <th scope="col">Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Symbol</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody class="table__body">
                  <tr
                    class="table__text"
                    v-for="item in currencies"
                    v-bind:key="item.currencyCode"
                  >
                    <td>{{ item.currencyCode }}</td>
                    <td>
                      {{ item.currencyName }}
                      <span v-if="item.isBaseCurrency" class="tag tag__success"
                        >Base</span
                      >
                    </td>
                    <td :class="getActivity(item.isActive)">
                      {{ item.isActive }}
                    </td>
                    <td>{{ item.currencySymbol }}</td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="button btn-light dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          Action
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-container"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            href="#"
                            class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#editCurrencyModal"
                            v-on:click="getCurrency(item.currencyCode)"
                            v-if="userCanEditCurrency"
                            >Edit</a
                          >
                          <a
                            href="#"
                            class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#deleteConfirmModal"
                            v-on:click="
                              openConfirmation(item.currencyCode, item.isActive)
                            "
                            v-if="
                              !item.isBaseCurrency &&
                              (userCanDeactivateCurrency ||
                                userCanActivateCurrency)
                            "
                            >{{ getItemName(item.isActive) }}</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination">
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div class="text--capital text--regular pagination__text">
                  row per page:
                  <span
                    class="d-inline-block text--primary"
                    style="padding-right: 15px"
                  >
                    <select @change="changeLength" v-model="size">
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="30">30</option>
                      <option :value="50">50</option>
                      <option :value="100">100</option>
                    </select>
                  </span>
                  <span class="d-inline-block text--primary">{{
                    !paginations.hasPreviousPage
                      ? paginations.pageIndex
                      : size * (paginations.pageIndex - 1) + 1
                  }}</span>
                  -
                  <span class="d-inline-block text--primary">{{
                    paginations.hasNextPage
                      ? size * paginations.pageIndex
                      : paginations.totalCount
                  }}</span>
                  of
                  <span>{{ paginations ? paginations.totalCount : 0 }}</span>
                </div>
                <div
                  class="pagination__buttons d-flex align-items-center justify-content-between"
                  v-if="paginations"
                >
                  <button
                    class="button button--sm text--regular"
                    @click="changePage('previous')"
                    :disabled="!paginations.hasPreviousPage"
                  >
                    <ion-icon name="chevron-back-outline"></ion-icon>
                  </button>
                  <button
                    v-for="page in paginations.totalPages"
                    :key="page"
                    :class="{
                      'text--white button--primary ':
                        paginations.pageIndex === page,
                    }"
                    class="button text--regular button--sm ml-2"
                    @click="changePage(page)"
                  >
                    {{ page }}
                  </button>
                  <button
                    class="button text--regular button--sm ml-2"
                    @click="changePage('next')"
                    :disabled="!paginations.hasNextPage"
                  >
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion__element">
          <div class="accordion__header">
            <h2 class="">
              <span class="accordion__header__title">
                Exchange Rates - From Nigerian naira (NGN)
              </span>
              <button
                class="btn accordion__header__button float-right"
                type="button"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
              >
                <span></span>
                <ion-icon name="chevron-down-outline"></ion-icon>
              </button>
            </h2>
          </div>

          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="accordion__body">
              <!-- <button
                class="button page__body__header__button ml-auto button--grey text--capital mb-3"
                type="button"
                data-toggle="modal"
                data-target="#newExchangeRateModal"
              >
                Add Exchange Rate <i class="ki ki-plus icon-sm ml-3"> </i>
              </button> -->
              <table class="table customtable">
                <thead class="table__header">
                  <tr class="table__header__text table-text">
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Buy Rate</th>
                    <th scope="col">Sell Rate</th>
                    <th scope="col">Active</th>
                    <th scope="col">Actions</th>
                    <!-- <th scope="col">Date Set</th> -->
                  </tr>
                </thead>
                <tbody class="table__body">
                  <tr
                    class="table__text"
                    v-for="item in exchangeRates"
                    v-bind:key="item.exchangeRateKey"
                  >
                    <td>{{ item.fromCurrencyCode }}</td>
                    <td>{{ item.toCurrencyCode }}</td>
                    <td>
                      <span v-if="item.buyRate !== 0">{{ item.buyRate }}</span>
                      <span class="text-danger" v-if="item.buyRate === 0"
                        >Not set</span
                      >
                    </td>
                    <td>
                      <span v-if="item.sellRate !== 0">{{
                        item.sellRate
                      }}</span>
                      <span class="text-danger" v-if="item.sellRate === 0"
                        >Not set</span
                      >
                    </td>
                    <td>
                      <span v-if="item.isActive" class="tag tag__success"
                        >Active</span
                      >
                      <span
                        v-if="!item.isActive"
                        class="tag tag__danger text-danger"
                        >Inactive</span
                      >
                    </td>
                    <td>
                      <div class="dropdown">
                        <button
                          class="button btn-light dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="true"
                        >
                          Action
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-container"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            href="#"
                            class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#editExchangeRateModal"
                            v-if="userCanSetExchangeRate"
                            v-on:click="getExchangeRate(item.exchangeRateKey)"
                            >Set Rate</a
                          >
                          <!-- <a
                            href="#"
                            class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#deleteExchangeConfirmModal"
                            v-on:click="
                              openExchangeConfirmation(item.exchangeRateKey)
                            "
                            >Delete</a
                          > -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="pagination">
              <div
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div class="text--capital text--regular pagination__text">
                  row per page:
                  <span
                    class="d-inline-block text--primary"
                    style="padding-right: 15px"
                  >
                    <select @change="changeLength2" v-model="size2">
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="30">30</option>
                      <option :value="50">50</option>
                      <option :value="100">100</option>
                    </select>
                  </span>
                  <span class="d-inline-block text--primary">{{
                    !exchangePaginate.hasPreviousPage
                      ? exchangePaginate.pageIndex
                      : size2 * (exchangePaginate.pageIndex - 1) + 1
                  }}</span>
                  -
                  <span class="d-inline-block text--primary">{{
                    exchangePaginate.hasNextPage
                      ? size2 * exchangePaginate.pageIndex
                      : exchangePaginate.totalCount
                  }}</span>
                  of
                  <span>{{
                    exchangePaginate ? exchangePaginate.totalCount : 0
                  }}</span>
                </div>
                <div
                  class="pagination__buttons d-flex align-items-center justify-content-between"
                  v-if="exchangePaginate"
                >
                  <button
                    class="button button--sm text--regular"
                    @click="changePage2('previous')"
                    :disabled="!exchangePaginate.hasPreviousPage"
                  >
                    <ion-icon name="chevron-back-outline"></ion-icon>
                  </button>
                  <button
                    v-for="page in exchangePaginate.totalPages"
                    :key="page"
                    :class="{
                      'text--white button--primary ':
                        exchangePaginate.pageIndex === page,
                    }"
                    class="button text--regular button--sm ml-2"
                    @click="changePage2(page)"
                  >
                    {{ page }}
                  </button>
                  <button
                    class="button text--regular button--sm ml-2"
                    @click="changePage2('next')"
                    :disabled="!exchangePaginate.hasNextPage"
                  >
                    <ion-icon name="chevron-forward-outline"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal__right" id="newCurrencyModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                id="formCloseButton"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Add Currency</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveForm">
                <div class="form__item">
                  <label class="form__label">Currency Code</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.currencyCode"
                    placeholder="Enter code"
                    v-bind:class="{
                      'input-required': currencyErr.currencyCode,
                    }"
                  />
                </div>
                <div class="form__item">
                  <label class="form__label">Currency Name</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.currencyName"
                    placeholder="Enter name"
                    v-bind:class="{
                      'input-required': currencyErr.currencyName,
                    }"
                  />
                </div>
                <div class="form__item">
                  <label class="form__label">Currency Symbol</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.currencySymbol"
                    placeholder="Enter symbol"
                    v-bind:class="{
                      'input-required': currencyErr.currencySymbol,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">Currency Symbol Position</label>
                  <select
                    class="select--lg form__input w-100"
                    v-model="form.currencySymbolPosition"
                    v-bind:class="{
                      'input-required': currencyErr.currencySymbolPosition,
                    }"
                  >
                    <option disabled>Select option</option>
                    <option value="BeforeNumber">Before Number</option>
                    <option value="AfterNumber">After Number</option>
                  </select>
                </div>

                <div class="row">
                  <div class="form__item col-md-6">
                    <label class="form__label">Digits After Decimal</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      v-model="form.digitsAfterDecimal"
                      v-bind:class="{
                        'input-required': currencyErr.digitsAfterDecimal,
                      }"
                      placeholder="Enter digits"
                      min="0"
                    />
                  </div>

                  <div class="form__item mt-5">
                    <label
                      for="interestpaid"
                      class="has-checkbox page__body__header__label text--capital mb-3 text--black"
                      >Is Base Currency
                      <input
                        type="checkbox"
                        id="interestpaid"
                        class="form- mr-4"
                        v-model="form.isBaseCurrency"
                        :checked="form.isBaseCurrency"
                      />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <!-- <div class="form-group">
                      <input
                        v-model="form.isBaseCurrency"
                        class="form__checkbox mr-4"
                        type="checkbox"
                      />
                    </div> -->
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="button form__action__text form__action__text--danger ml-5"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="formCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="editCurrencyModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                id="editformCloseButton"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Edit Currency</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveEditForm">
                <div class="form__item">
                  <label class="form__label"
                    >Currency Code: {{ form.currencyCode }}</label
                  >
                </div>
                <div class="form__item">
                  <label class="form__label">Currency Name</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.currencyName"
                    placeholder="Enter name"
                    v-bind:class="{
                      'input-required': currencyErr.currencyName,
                    }"
                  />
                </div>
                <div class="form__item">
                  <label class="form__label">Currency Symbol</label>
                  <input
                    class="input form__input w-100"
                    v-model="form.currencySymbol"
                    placeholder="Enter symbol"
                    v-bind:class="{
                      'input-required': currencyErr.currencySymbol,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">Currency Symbol Position</label>
                  <select
                    class="select--lg form__input w-100"
                    v-model="form.currencySymbolPosition"
                    v-bind:class="{
                      'input-required': currencyErr.currencySymbolPosition,
                    }"
                  >
                    <option disabled>Select option</option>
                    <option value="BeforeNumber">Before Number</option>
                    <option value="AfterNumber">After Number</option>
                  </select>
                </div>

                <div class="row">
                  <div class="form__item col-md-6">
                    <label class="form__label">Digits After Decimal</label>
                    <input
                      class="input form__input w-100"
                      type="number"
                      v-model="form.digitsAfterDecimal"
                      v-bind:class="{
                        'input-required': currencyErr.digitsAfterDecimal,
                      }"
                      placeholder="Enter digits"
                      min="0"
                    />
                  </div>

                  <div class="form__item">
                    <label class="form__label">Is Base Currency</label>
                    <div class="form-group">
                      <input
                        v-model="form.isBaseCurrency"
                        class="form__checkbox mr-4"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="button form__action__text form__action__text--danger ml-5"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="editformCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <a
                ><span>
                  <ion-icon name="close-outline"></ion-icon> Close</span
                ></a
              >
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to proceed?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <!-- <h6 class="my-4">
                  When you delete this currency from your list, you won't be
                  able to access it again,
                </h6> -->
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                Cancel
              </button>
              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteCurrency(deleteCode, isActive)"
              >
                {{ nameType }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__right"
      id="newExchangeRateModal"
      aria-hidden="true"
    >
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Add Exchange Rate</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveExchangeRateForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form__item">
                      <label class="form__label">From Currency Code</label>
                      <input
                        class="input form__input w-100"
                        v-model="exchangeForm.fromCurrencyCode"
                        placeholder="Enter code"
                        v-bind:class="{
                          'input-required': exchangeErr.fromCurrencyCode,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form__item">
                      <label class="form__label">To Currency Code</label>
                      <input
                        class="input form__input w-100"
                        v-model="exchangeForm.toCurrencyCode"
                        placeholder="Enter code"
                        v-bind:class="{
                          'input-required': exchangeErr.toCurrencyCode,
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form__item">
                      <label class="form__label">Buy Rate</label>
                      <input
                        class="input form__input w-100"
                        v-model="exchangeForm.buyRate"
                        placeholder="Enter rate"
                        type="number"
                        min="0"
                        step="0.01"
                        v-bind:class="{
                          'input-required': exchangeErr.buyRate,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form__item">
                      <label class="form__label">Sell Rate</label>
                      <input
                        class="input form__input w-100"
                        v-model="exchangeForm.sellRate"
                        placeholder="Enter rate"
                        type="number"
                        min="0"
                        step="0.01"
                        v-bind:class="{
                          'input-required': exchangeErr.sellRate,
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form__item">
                      <label class="form__label">Startdate</label>
                      <input
                        class="input form__input w-100"
                        v-model="exchangeForm.startDate"
                        placeholder="Enter Date"
                        type="date"
                        v-bind:class="{
                          'input-required': exchangeErr.startDate,
                        }"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form__item">
                      <label class="form__label">Enddate</label>
                      <input
                        class="input form__input w-100"
                        v-model="exchangeForm.endDate"
                        placeholder="Enter Date"
                        type="date"
                        v-bind:class="{
                          'input-required': exchangeErr.endDate,
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="form__item">
                    <label class="form__label">Active</label>
                    <div class="form-group">
                      <input
                        v-model="exchangeForm.isActive"
                        class="form__checkbox mr-4"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="button form__action__text form__action__text--danger ml-5"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="exchangeFormCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__right"
      id="editExchangeRateModal"
      aria-hidden="true"
    >
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <a
                data-dismiss="modal"
                id="editexchangeFormCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
                class="modal__close modal__close--black"
              >
                <ion-icon name="arrow-back-outline"></ion-icon>
              </a>

              <div class="modal__header__title">
                <h5 class="modal__head__title">Edit Exchange Rate</h5>
                <br />
              </div>
            </div>
            <div class="modal__body" v-if="exchangeRate != null">
              <form class="form" @submit="saveEditExchangeRateForm">
                <div class="row">
                  <div class="form__item">
                    <label class="form__label"
                      >From Currency Code:
                      {{ exchangeRate.fromCurrencyCode }}</label
                    >
                    <br />
                    <label class="form__label"
                      >To Currency Code:
                      {{ exchangeRate.toCurrencyCode }}</label
                    >:
                  </div>
                </div>

                <div class="row">
                  <div class="form__item">
                    <label class="form__label">Buy Rate</label>
                    <input
                      class="input form__input w-100"
                      v-model="exchangeForm.buyRate"
                      placeholder="Enter rate"
                      type="number"
                      min="0"
                      step="0.01"
                      v-bind:class="{ 'input-required': exchangeErr.buyRate }"
                    />
                  </div>
                  <div class="form__item">
                    <label class="form__label">Sell Rate</label>
                    <input
                      class="input form__input w-100"
                      v-model="exchangeForm.sellRate"
                      placeholder="Enter rate"
                      type="number"
                      min="0"
                      step="0.01"
                      v-bind:class="{
                        'input-required': exchangeErr.sellRate,
                      }"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form__item">
                    <label class="form__label">Start date</label>
                    <input
                      class="input form__input w-100"
                      v-model="exchangeForm.startDate"
                      placeholder="Enter Date"
                      type="date"
                      v-bind:class="{
                        'input-required': exchangeErr.startDate,
                      }"
                    />
                  </div>
                  <div class="form__item">
                    <label class="form__label">End date</label>
                    <input
                      class="input form__input w-100"
                      v-model="exchangeForm.endDate"
                      placeholder="Enter Date"
                      type="date"
                      v-bind:class="{ 'input-required': exchangeErr.endDate }"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="form__item">
                    <label class="form__label">Active</label>
                    <div class="form-group">
                      <input
                        v-model="exchangeForm.isActive"
                        class="form__checkbox mr-4"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="button form__action__text form__action__text--danger ml-5"
                  data-dismiss="modal"
                  aria-label="Close"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteExchangeConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this Exchange Rate ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this Exchange Rate from your list, you won't
                  be able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="exchangeCloseButton"
              >
                Cancel
              </button>

              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-on:click="deleteExchangeRate(deleteCode)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";
import currencyService from "@/core/services/general-setup-service/currency.service";
import exchangeRateService from "@/core/services/general-setup-service/exchange-rate.service";
export default Vue.extend({
  name: "currency",
  data: function () {
    return {
      content_header: {
        title: "Currency",
        subTitle: "",
      },
      deleteCode: null,
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "",
      },
      form: {
        currencyCode: null,
        currencySymbolPosition: null,
        digitsAfterDecimal: 0,
        currencyName: null,
        currencySymbol: null,
        isBaseCurrency: false,
      },
      currencyErr: {
        currencyCode: false,
        currencySymbolPosition: false,
        digitsAfterDecimal: false,
        currencyName: false,
        currencySymbol: false,
      },

      exchangeForm: {
        fromCurrencyCode: null,
        toCurrencyCode: null,
        buyRate: 0,
        sellRate: 0,
        startDate: null,
        endDate: null,
        isActive: true,
      },
      exchangeErr: {
        fromCurrencyCode: false,
        toCurrencyCode: false,
        buyRate: false,
        sellRate: false,
        startDate: false,
        endDate: false,
      },

      currency: null,
      currencies: [],

      exchangeRate: null,
      exchangeRates: [],
      paginations: {},
      pages: 1,
      size: 20,
      exchangePaginate: {},
      pages2: 1,
      size2: 20,
      isActive: false,
      nameType: "",
    };
  },
  methods: {
    getActivity(status) {
      if (status) return "text--success";
      return "text--danger";
    },
    getItemName(status) {
      if (status) return "Deactivate";
      return "Activate";
    },
    async getCurrencies() {
      try {
        this.isLoading = true;
        const res = await currencyService.get({
          pageSize: this.size,
          pageIndex: this.pages,
        });
        this.currencies = res.data.data.items;
        this.paginations = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e?.response?.data?.message;
        if (error) this.alertError(error);
      }
    },
    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getCurrencies();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);
      this.pages = 1;
      this.getCurrencies();
    },
    async getExchangeRates() {
      try {
        this.isLoading = true;
        const res = await exchangeRateService.get({
          pageSize: this.size2,
          pageIndex: this.pages2,
        });
        this.exchangeRates = res.data.data.items;
        this.exchangePaginate = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e?.response?.data?.message;
        if (error) this.alertError(error);
      }
    },
    changePage2(type) {
      if (type === "next") {
        this.pages2++;
      } else if (type === "previous") {
        this.pages2--;
      } else {
        this.pages2 = type;
      }

      this.getExchangeRates();
    },
    changeLength2(e) {
      const { value } = e.target;
      this.size2 = parseInt(value);
      this.pages2 = 1;
      this.getExchangeRates();
    },

    async getCurrency(code) {
      try {
        this.currency = null;
        this.isLoading = true;
        const res = await currencyService.getByCode(code);
        this.currency = res.data.data;

        this.form.currencyCode = this.currency.currencyCode;
        this.form.currencySymbolPosition = this.currency.currencySymbolPosition;
        this.form.digitsAfterDecimal = this.currency.digitsAfterDecimal;
        this.form.currencyName = this.currency.currencyName;
        this.form.currencySymbol = this.currency.currencySymbol;
        this.form.isBaseCurrency = this.currency.isBaseCurrency;

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getExchangeRate(key) {
      try {
        this.exchangeRate = null;
        this.isLoading = true;
        const res = await exchangeRateService.getById(key);
        this.exchangeRate = res.data.data;

        this.exchangeForm.fromCurrencyCode = this.exchangeRate.fromCurrencyCode;
        this.exchangeForm.toCurrencyCode = this.exchangeRate.toCurrencyCode;
        this.exchangeForm.buyRate = this.exchangeRate.buyRate;
        this.exchangeForm.sellRate = this.exchangeRate.sellRate;
        this.exchangeForm.startDate = this.formatDate(
          this.exchangeRate.startDate
        );
        this.exchangeForm.endDate = this.formatDate(this.exchangeRate.endDate);
        this.exchangeForm.isActive = this.exchangeRate.isActive;

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },

    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateCurrencyForm()) {
        try {
          this.isSaving = true;
          await currencyService.create(this.form);
          this.getCurrencies();
          this.getExchangeRates();
          document.getElementById("formCloseButton").click();
          this.alertSuccess("New currency added");
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateCurrencyForm()) {
        try {
          this.isSaving = true;

          this.form.currencyCode = this.currency.currencyCode;
          await currencyService.update(this.currency.currencyCode, this.form);
          this.getCurrencies();
          this.getExchangeRates();
          document.getElementById("editformCloseButton").click();
          this.alertSuccess("Currency updated");
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },

    validateCurrencyForm() {
      this.clearCurrencyValidation();
      if (
        this.form.currencyCode &&
        this.form.currencySymbolPosition &&
        this.form.digitsAfterDecimal >= 0 &&
        this.form.currencyName &&
        this.form.currencySymbol
      ) {
        return true;
      }

      if (!this.form.currencyCode) {
        this.currencyErr.currencyCode = true;
      }
      if (!this.form.currencySymbolPosition) {
        this.currencyErr.currencySymbolPosition = true;
      }
      if (this.form.digitsAfterDecimal < 0) {
        this.currencyErr.digitsAfterDecimal = true;
      }
      if (!this.form.currencyName) {
        this.currencyErr.currencyName = true;
      }
      if (!this.form.currencySymbol) {
        this.currencyErr.currencySymbol = true;
      }
      return false;
    },

    clearCurrencyValidation() {
      this.currencyErr = {
        currencyCode: false,
        currencySymbolPosition: false,
        digitsAfterDecimal: false,
        currencyName: false,
        currencySymbol: false,
      };
    },

    saveExchangeRateForm: async function (e) {
      e.preventDefault();
      if (this.validateExchangeForm()) {
        try {
          this.isSaving = true;
          await exchangeRateService.create(this.exchangeForm);
          this.getExchangeRates();
          document.getElementById("exchangeFormCloseButton").click();
          this.clearForm();
          this.alertSuccess("New exchange rate added");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    saveEditExchangeRateForm: async function (e) {
      e.preventDefault();
      if (this.validateExchangeForm()) {
        try {
          this.isSaving = true;

          this.exchangeForm.exchangeRateKey = this.exchangeRate.exchangeRateKey;
          await exchangeRateService.update(
            this.exchangeRate.exchangeRateKey,
            this.exchangeForm
          );
          this.getExchangeRates();
          document.getElementById("editexchangeFormCloseButton").click();
          this.clearForm();
          this.alertSuccess("Exchange rate updated");
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      }
    },
    validateExchangeForm() {
      this.clearExchangeValidation();
      if (
        this.exchangeForm.fromCurrencyCode &&
        this.exchangeForm.toCurrencyCode &&
        this.exchangeForm.buyRate &&
        this.exchangeForm.sellRate &&
        this.exchangeForm.startDate &&
        this.exchangeForm.endDate
      ) {
        return true;
      }

      if (!this.exchangeForm.fromCurrencyCode) {
        this.exchangeErr.fromCurrencyCode = true;
      }
      if (!this.exchangeForm.toCurrencyCode) {
        this.exchangeErr.toCurrencyCode = true;
      }
      if (!this.exchangeForm.buyRate) {
        this.exchangeErr.buyRate = true;
      }
      if (!this.exchangeForm.sellRate) {
        this.exchangeErr.sellRate = true;
      }
      if (!this.exchangeForm.startDate) {
        this.exchangeErr.startDate = true;
      }
      if (!this.exchangeForm.endDate) {
        this.exchangeErr.endDate = true;
      }

      return false;
    },
    clearExchangeValidation() {
      this.exchangeErr = {
        fromCurrencyCode: false,
        toCurrencyCode: false,
        buyRate: false,
        sellRate: false,
        startDate: false,
        endDate: false,
      };
    },

    clearForm() {
      this.form = {
        currencyCode: null,
        currencySymbolPosition: null,
        digitsAfterDecimal: 0,
        currencyName: null,
        currencySymbol: null,
        isBaseCurrency: false,
      };
      this.currency = null;

      this.exchangeForm = {
        fromCurrencyCode: null,
        toCurrencyCode: null,
        buyRate: 0,
        sellRate: 0,
        startDate: null,
        endDate: null,
        isActive: false,
      };
      this.exchangeRate = null;
    },

    async deleteCurrency(code, isActive) {
      this.isDeleting = true;
      try {
        await currencyService.delete(code, isActive ? false : true);
        this.alertSuccess("Successful");
        document.getElementById("closeButton").click();
        this.isDeleting = false;
        this.getCurrencies();
        this.getExchangeRates();
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    openConfirmation(code, isActive) {
      this.deleteCode = code;
      this.isActive = isActive;
      this.nameType = this.getItemName(isActive);
    },

    async deleteExchangeRate(key) {
      await exchangeRateService.delete(key);
      this.alertSuccess("Deleted");
      document.getElementById("exchangeCloseButton").click();
      this.getExchangeRates();
    },
    openExchangeConfirmation(key) {
      this.deleteCode = key;
    },

    formatDate(dateStr) {
      const d = new Date(dateStr);
      let result =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1 > 9 ? "" : "0") +
        (d.getMonth() + 1) +
        (d.getDate() > 9 ? "" : "0") +
        "-" +
        d.getDate();
      return result;
    },

    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getCurrencies();
    this.getExchangeRates();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateCurrency() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = user;
      return permissionsList.CanCreateCurrency;
    },
    userCanEditCurrency() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = user;
      return permissionsList.CanEditCurrency;
    },
    userCanDeleteCurrency() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = user;
      return permissionsList.CanDeleteCurrency;
    },
    userCanDeactivateCurrency() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = user;
      return permissionsList.CanDeactivateCurrency;
    },
    userCanActivateCurrency() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = user;
      return permissionsList.CanActivateCurrency;
    },
    userCanSetExchangeRate() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = user;
      return permissionsList.CanSetRate;
    },
  },
});
</script>
