import ApiService from "@/core/services/general.service";
import JwtService from "@/core/services/jwt.service";

// Endpoints
const resource = process.env.VUE_APP_API_BASE_GENERAL_URL + "Currency";

const exchangeRateService = {
  get({ pageSize, pageIndex }) {
    const newResource = `${resource}/exchangeRate/?pageSize=${pageSize}&pageIndex=${pageIndex}`;
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(newResource, "");
    }
  },

  getById(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get(resource, `${key}/exchangeRate`);
    }
  },

  create(data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(`${resource}/exchangeRate`, data);
    }
  },

  update(key, data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.update(resource, `${key}/exchangeRate`, data);
    }
  },

  delete(key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.delete(
        `${resource}/${key}/exchangerate`,
        `${key}/exchangeRate`
      );
    }
  },
};

export default exchangeRateService;
